
import { computed, defineComponent, onMounted, Ref, ref, watch } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import AuthService from "@/services/AuthService";
import { useRouter } from "vue-router";
import { Modal } from "bootstrap";
import axios from "axios";
import ModalInserirNosSelecionados from "./components/ModalInserirNosSelecionados.vue";
import ModalExcluirSelecionados from "./components/ModalExcluirSelecionados.vue";
import ModalEditarUnicoSelecionado from "./components/ModalEditarUnicoSelecionado.vue";
import ModalExcluirUnicoVeiculo from "./components/ModalExcluirUnicoVeiculo.vue";
import ModalEnviarPlanilha from "./components/ModalEnviarPlanilha.vue";
import Button from "@/components/Button.vue"
import Title from "@/components/Title.vue";
import useEmitter from "@/composables/Emmiter";

export default defineComponent({
  name: "FiltroVeiculos",
  components: {
    ModalInserirNosSelecionados,
    ModalExcluirSelecionados,
    ModalEditarUnicoSelecionado,
    ModalExcluirUnicoVeiculo,
    ModalEnviarPlanilha,
    Title,
    Button,
  },

  setup() {
    let auxModal;
    const emitter = useEmitter();
    const loading = ref(false);

    const cleanModal = ref(false);

    const store = useStore();
    const router = useRouter();
    const usuario = AuthService.getUsuario();
    const nivelCargo = usuario.nivelCargo;

    //variaveis de controle dos cards
    const openListCars = ref(false);
    const marcasDeVeiculos: Ref<any> = ref([]);
    const tiposDeProdutosRefEntrada: Ref<any> = ref([]);
    const tiposDeProdutosRef: Ref<any> = ref([]);
    const marcaSelecionada = ref("");
    const allModelos: Ref<any> = ref([]);
    const veiculoSelecionado = ref("");
    const yearInit: Ref<Number[]> = ref([]);
    const yearFinal: Ref<Number[]> = ref([]);
    const clientYearInit = ref("");
    const clientYearFinal = ref("");
    const inserirSelecionados = ref(false);
    const excluirSelecionados = ref(false);
    const editUnique = ref(false);
    const deleteUnique = ref(false);
    const butttonReferencia = ref(true);
    const targetClickCar: Ref<any> = ref({
      car: {},
      products: [],
    });
    const modelSelected: Ref<Array<any>> = ref([]);
    const modelsListFiltered: Ref<Array<any>> = ref([]);
    const modelsYearListFiltered: Ref<Array<any>> = ref([]);
    const tipoSelected = ref("");
    const marcaDigitada = ref("");
    const referenciaDigitada = ref("");
    const familyVehicles: Ref<Array<any>> = ref([]);
    const allModelosFiltered: Ref<any> = ref([]);
    const abaFiltro: Ref<any> = ref(true);
    const inputTypeMotor = ref("");
    const fipeNumero: Ref<string> = ref("");

    //variaveis de controle da tabela/checkbox
    const checkBoxPrincipal = ref(false);
    const clickedVeiculo: Ref<String[]> = ref([]);
    const clickedAllVeiculos: Ref<Array<any>> = ref([]);

    const productsList: Ref<any> = ref([]);
    const produtosCar: Ref<any> = ref([]);
    const productsFiltered: Ref<any> = ref([]);
    const productsMarcas: Ref<any> = ref([]);
    const spanWarning = ref(false);
    const paginasNumeradas: Ref<any> = ref([]);

    //FUNCAO PARA PEGAR O TOKEN
    const getToken = async () => {
      return localStorage.getItem("token_usuario");
    };
    getToken();

    // ____________________________________________________________________________________

    //FUNCAO PARA BUSCAR OS TIPOS DE PRODUTOS QUE PODEM SER CADASTRADOS
    async function getTipoProdutos() {
      try {
        const res = await axios.get("tipoProdutos", {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        });
        tiposDeProdutosRefEntrada.value = res.data;
        tiposDeProdutosRef.value = tiposDeProdutosRefEntrada.value.filter(
          product => product.nome.split("_")[0] != "oleo" && product.nome.split("_")[0] != "pneu"
        );
      } catch (err) {
        console.log(err);
      }
    }

    //FUNCAO QUE BUSCA AS MARCAS AUTORIZADAS PARA SEREM ADICIONADAS
    async function getMarcasProdutos() {
      loading.value = true;
      try {
        const res = await axios.post("getMarcasFiltros", "", {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        });
        productsMarcas.value = res.data;
      } catch (err) {
        console.log(err);
      } finally {
        loading.value = false;
      }
    }
    getMarcasProdutos();

    //FUNCAO QUE BUSCA AS MARCAS DOS CARROS EM NOSSA BASE
    async function getMarcas() {
      try {
        const res = await axios.get("marcas", {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        });
        marcasDeVeiculos.value = res.data;
      } catch (err) {
        console.log(err);
      }
    }

    // sempre que o usuario escolhe uma marca, a funcao que tras o modelos filtrados ordenados e organizados é chamada.
    // as options sempre estaram carregadas.
    watch(
      () => marcaSelecionada.value,
      () => {
        yearInit.value = [];
        yearFinal.value = [];
        veiculoSelecionado.value = "";
        clientYearInit.value = "";
        clientYearFinal.value = "";
        openListCars.value = false;
      }
    );

    //FUNCAO QUE FILTRA OS MODELOS COM BASE NA SUA MARCA FABRICANTE
    async function getModelosPorMarcas(modelSelect: string) {
      yearInit.value = [];
      yearFinal.value = [];
      allModelosFiltered.value = [];
      try {
        const res = await axios.post(
          "modelosPorMarcas",
          { marca: modelSelect, fipe: !fipeNumero.value ? null : fipeNumero.value },
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        );
        allModelos.value = res.data;
        if (fipeNumero.value) return (modelsYearListFiltered.value = allModelos.value);
        allModelosFiltered.value = allModelos.value
          .map(family => family.modelo.split(" ")[0])
          .reduce((unico: any, item: any) => (unico.includes(item) ? unico : [...unico, item]), [])
          .sort(function Crescente(a: any, b: any) {
            return a > b ? 1 : a < b ? -1 : 0;
          });
      } catch (err) {
        console.log(err);
      }
    }

    watch(
      () => veiculoSelecionado.value,
      () => {
        yearInit.value = [];
        yearFinal.value = [];
        clientYearInit.value = "";
        clientYearFinal.value = "";
        filterModel(veiculoSelecionado.value);
      }
    );

    // esse hook watch, monitora toda vez que seleciona um novo fabricante. ele é acionado e ordena os modelos evitando a repetição do nome
    // das familias do veiculo (PALIO, GOL, ETC), mais na exibição da lista, é exibido todos. A familia so é exibida uma vez.

    //FILTRO PARA ORGANIZACAO DOS MODELOS BUSCADOS COM BASE NO ANO, DO MAIS ANTIGO PARA O MAIS NOVO
    async function filterModel(veiculoClicado: string) {
      yearInit.value = [];
      yearFinal.value = [];
      modelsListFiltered.value = await allModelos.value
        .filter(atual => atual.modelo.split(" ")[0].toLowerCase() == veiculoClicado.split(" ")[0].toLowerCase())
        .sort(Crescente);
      yearEnterOptions();
    }
    // Callback function de ordenação dos carros exibidos por ano de fabricação
    function Crescente(a, b) {
      return a.ano > b.ano ? 1 : a.ano < b.ano ? -1 : 0;
    }

    function filterYearModel() {
      const allCars = modelsListFiltered.value.filter(
        atual => Number(atual.ano.split(" ")[0]) >= Number(clientYearInit.value) && Number(atual.ano.split(" ")[0]) <= Number(clientYearFinal.value)
      );

      if (!inputTypeMotor.value) {
        return (modelsYearListFiltered.value = allCars);
      }
      if (inputTypeMotor.value.split(" ").length > 1) {
        return (modelsYearListFiltered.value = allCars.filter(
          motorOUvalvulas =>
            motorOUvalvulas.modelo.toLowerCase().includes(inputTypeMotor.value.split(" ")[0].toLowerCase()) &&
            motorOUvalvulas.modelo.toLowerCase().includes(inputTypeMotor.value.split(" ")[1].toLowerCase())
        ));
      }
      modelsYearListFiltered.value = allCars.filter(motor => motor.modelo.toLowerCase().includes(inputTypeMotor.value.toLowerCase()));
    }

    // essa função cria o ano inicial de busca de veiculo. ela sempre vai ter como base a data atual.
    // usado o for comum para ficar mais claro o funcionamento para qualquer um.
    // o array de modelos é ordenado anteriormente por ano, e por meio de acesso por posição do array, ele vira o primeiro ano
    // do modelo do carro disponivel. o ultimo disponivel mesma coisa, é a ultima posiçao do array de modelos.
    //a funcao de ano de saida, segue o mesmo padrao, acrescentando no ultimo ano +1 pra evitar problema com ano de fabricaçao e modelo diferentes.
    async function yearEnterOptions() {
      const initBaseYear = Number(modelsListFiltered.value[0]?.ano.split(" ")[0]);
      const initBaseYearFinal = Number(modelsListFiltered.value[modelsListFiltered.value.length - 1]?.ano.split(" ")[0]);
      for (let i = initBaseYear; i <= initBaseYearFinal; i++) {
        yearInit.value.push(i);
      }
      yearOutOptions();
    }

    // esse hook watch libera para o usuario o ano de saida do veiculo somente quando ele seleciona o ano de entrada.
    // de moto a evitar erros do usuario ou requisiçoes desnecessarias com dados errados.
    watch(
      () => clientYearInit.value,
      () => {
        yearOutOptions();
      }
    );

    function yearOutOptions() {
      const initBaseYearFinal = Number(modelsListFiltered.value[modelsListFiltered.value.length - 1]?.ano.split(" ")[0]);
      yearFinal.value = [];
      for (let i = Number(clientYearInit.value); i <= initBaseYearFinal + 1; i++) {
        yearFinal.value.push(i);
      }
    }

    // _________________________________________________________________________________________

    //FUNCAO QUE SELECIONA TODOS OS VEICULOS NO CLICK NA CHECKBOX
    const myCheckAll = () => {
      (async () => {
        loading.value = true;
        clickedAllVeiculos.value = [];
        if (checkBoxPrincipal.value == false) {
          modelsListFiltered.value.map(allVeiculos => clickedAllVeiculos.value.push(allVeiculos));
        }
      })().then(() => {
        loading.value = false;
      });
    };

    const checkOne = () => {
      checkBoxPrincipal.value = false;
    };

    //FUNCAO QUE PEGA OS DADOS DO CARRO EXATAMENTE SELECIONADO
    async function clickCar(car: any) {
      loading.value = true;
      const { id } = car;
      try {
        const res = await axios.post("buscaFiltro", car, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        });
        produtosCar.value = res.data;

        productsList.value = produtosCar.value.data.filtros.map((item: any) => {
          return item;
        });

        let carTarget = modelsListFiltered.value.find(carClicado => carClicado.id === Number(id));
        targetClickCar.value = {
          car: carTarget,
          products: productsList,
        };
      } catch (err) {
        console.log(err);
      } finally {
        loading.value = false;
      }
    }

    function goToHome() {
      if (nivelCargo == 3) {
        router.push({ name: "erroConcessionaria" });
      } else {
        router.push({ name: "login" });
      }
    }

    //FUNCAO QUE BUSCA OS FILTROS DO CARRO SELECIONADO
    async function findFilteredProduct() {
      const veiculoSelected = {
        marcaVeiculo: marcaSelecionada.value,
        familia: veiculoSelecionado.value,
        tipo: Number(tipoSelected.value.split("-")[1]),
        marcaProduto: marcaDigitada.value,
      };
      try {
        const { data } = await axios.post("buscaFiltro", veiculoSelected, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        });
      } catch (err) {
        console.log(err);
      }
    }

    function verifySelectValues() {
      if (fipeNumero.value) return (spanWarning.value = false), (openListCars.value = true), getModelosPorMarcas("valorNulo");

      if (!marcaSelecionada.value || !veiculoSelecionado.value || !clientYearInit.value || !clientYearFinal.value) {
        spanWarning.value = true;
        return;
      }
      spanWarning.value = false;
      openListCars.value = true;
    }

    function usePagination() {
      const state = ref({
        currentPage: 1,
        itemsPerPage: 20,
        itemsOnPage: 0,
      });

      const pageCount = computed(() => Math.ceil(modelsYearListFiltered?.value.length / state.value.itemsPerPage));

      const itemsOnPage = computed(() => {
        const startIndex = (state.value.currentPage - 1) * state.value.itemsPerPage;
        const endIndex = startIndex + state.value.itemsPerPage;
        return modelsYearListFiltered.value.slice(startIndex, endIndex);
      });

      const setCurrentPage = page => {
        state.value.currentPage = page;
        state.value.itemsOnPage = itemsOnPage.value.length;
      };

      return {
        state,
        setCurrentPage,
        pageCount,
        itemsOnPage,
      };
    }
    const { state, setCurrentPage, pageCount, itemsOnPage } = usePagination();

    const numberPages = () => {
      for (let i = 1; i <= pageCount.value; i++) {
        paginasNumeradas.value = i;
      }
    };

    watch(
      () => clickedAllVeiculos.value,
      () => (clickedAllVeiculos.value.length == 0 ? (butttonReferencia.value = true) : (butttonReferencia.value = false))
    );

    function alterarExibição(x) {
      abaFiltro.value = x;
    }

    // essa funcao de ano de saida é reflexo total do ano de entrada, o que impede alguns erros do usuario
    // ano modelo, pode ser igual ao ano de fabricação , mais nunca podera ser menor

    //funcao de modal recebe o id do modal, de forma a evitar a repeticao de codigo desnecessaria
    //estava tendo um problema com o modal conservando info de elemento anterior, somente no html, entao agora eu destruo o modal com v-if e ele sempre vem zerado
    const openModal = (id: string) => {
      (async () => {
        if (id === "editarUnicoSelecionado") cleanModal.value = true;
      })().then(() => {
        const auxElement = document.querySelector(`#${id}`);
        auxModal = new Modal(auxElement);
        auxModal.show();
      });
    };

    emitter.on("filtro-veiculos", async params => {
      modelsYearListFiltered.value = params.veiculosOUfipe[0];
      modelsListFiltered.value = params.veiculosOUfipe[1];
      openListCars.value = true;
      setCurrentPage(1), numberPages();
    });

    const closeModal = () => {
      cleanModal.value = false;
    };
    onMounted(() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
      getMarcas();
      getTipoProdutos();
    });

    return {
      goToHome,
      router,
      yearInit,
      yearFinal,
      clientYearInit,
      clientYearFinal,
      inserirSelecionados,
      excluirSelecionados,
      deleteUnique,
      editUnique,
      checkBoxPrincipal,
      clickedVeiculo,
      myCheckAll,
      clickedAllVeiculos,
      checkOne,
      butttonReferencia,
      openModal,
      clickCar,
      targetClickCar,
      marcasDeVeiculos,
      marcaSelecionada,
      getModelosPorMarcas,
      allModelos,
      veiculoSelecionado,
      modelSelected,
      filterModel,
      modelsListFiltered,
      modelsYearListFiltered,
      yearEnterOptions,
      filterYearModel,
      tiposDeProdutosRef,
      tipoSelected,
      marcaDigitada,
      referenciaDigitada,
      findFilteredProduct,
      allModelosFiltered,
      familyVehicles,
      productsMarcas,
      productsFiltered,
      loading,
      openListCars,
      verifySelectValues,
      spanWarning,
      abaFiltro,
      alterarExibição,
      state,
      setCurrentPage,
      pageCount,
      inputTypeMotor,
      itemsOnPage,
      numberPages,
      paginasNumeradas,
      cleanModal,
      closeModal,
      fipeNumero,
    };
  },
});
// #testeSelect .select-trigger .el-input{
//   border: 1rem solid blue !important;
// }

// .el-select .select-trigger .el-input .el-input--suffix .el-input__inner .el-input__suffix .el-input__suffix-inner .el-select__caret .el-input__icon .el-icon-arrow-up {
//    background: red !important;
// }
// .el-select .select-trigger {
//   border:1rem solid blue !important;
// }
