
import { defineComponent, ref, Ref, watch } from "vue";
import useAlert from "@/composables/Alert";
import Button from "@/components/Button.vue"
import axios from "axios";

export default defineComponent({
  name: "ModalInfoVeiculos",
  components: {
    Button,
  },
  emits: ["refresh"],
  props: {
    targetClickCar: Object,
  },
  setup(props) {
    const { showTimeAlert } = useAlert();
    const CarUpdated: Ref<any> = ref({});
    const myCar: Ref<any> = ref({});

    //FUNCAO PARA PEGAR O TOKEN
    const getToken = async () => {
      return localStorage.getItem("token_usuario");
    };

    watch(
      () => props.targetClickCar,
      () =>
        (myCar.value = {
          id: props.targetClickCar?.car.id,
          marca: props.targetClickCar?.car.marca,
        })
    );

    watch(
      () => myCar.value,
      () => refreshCarInfo()
    );

    const updateSend = async e => {
      e.preventDefault();
      try {
        const res = await axios.post(
          "atualizaDadosVeiculo",
          { ...CarUpdated.value },
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        );
        showTimeAlert("Veículo atualizado com sucesso!");
      } catch (err) {
        showTimeAlert("Não foi possivel atualizar o veículo.", "error");
      } finally {
        refreshCarInfo();
      }
    };

    async function refreshCarInfo() {
      const { marca } = myCar.value;
      const { id } = myCar.value;
      try {
        const res = await axios.post(
          "modelosPorMarcas",
          { marca: marca },
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        );
        const newRes = res.data.filter((car: any) => car.id === id);
        CarUpdated.value = {
          id: newRes[0].id,
          codModelo: newRes[0].codModelo,
          fipe: newRes[0].fipe,
          ordem: newRes[0].ordem,
          prioridade: newRes[0].prioridade,
          marca: newRes[0].marca,
          modelo: newRes[0].modelo,
          ano: newRes[0].ano,
          ano2: newRes[0].ano2,
          manual: newRes[0].manual,
        };
      } catch (err) {
        console.log(err);
      }
    }

    return {
      updateSend,
      CarUpdated,
    };
  },
});
