
import { defineComponent, onActivated, onBeforeUnmount, onBeforeUpdate, onMounted, onUpdated, PropType, Ref, ref, watch } from "vue";
import axios from "axios";
import Filter from "../FiltroVeiculos.vue";
import useAlert from "@/composables/Alert";
import Button from "@/components/Button.vue"
import { ElNotification } from "element-plus";
import CardDeFiltros from "./CardDeFiltros.vue";
import { Modal } from "bootstrap";
import ModalInfoVeiculo from "./ModalInfoVeiculo.vue";
import { lowerFirst } from "lodash";

interface ITargetClickCar {
  car: ICarInfo;
  products: Array<any>;
}

interface ICarInfo {
  marca: string;
  fipe: string;
  modelo: string;
  ano: string;
  lubrificante_ilsac: string;
  carter_com_filtro: string;
  lubrificante_api: string;
  lubrificante_acea: string;
  lubrificante_oem: string;
  lubrificante_base: string;
  lubrificante_sae: string;
}

export default defineComponent({
  emits: ["editarUnicoSelecionado", "attCar", "close"],
  components: { Filter,
    CardDeFiltros,
    ModalInfoVeiculo ,
    Button
  },
  props: {
    targetClickCar: {
      type: Object as PropType<ITargetClickCar>,
      required: true,
    },
    tiposDeProdutosRef: Array,
    productsMarcas: Array,
  },
  setup(props, { emit }) {
    let auxModal;
    const loading = ref(false);
    //ALERTS TOASTS
    const { showTimeAlert } = useAlert();
    const spanWarning = ref(false);

    //VARIAVEIS DE CONTROLE
    const openLub = ref(false);
    const allList = ref(true);
    const tipoSelected: Ref<Array<any>> = ref([]);
    const myProps: Ref<any> = ref([]);
    const tipo = ref("");
    const tipo1 = ref("1");
    const marca = ref("");
    const referencia = ref("");
    const productsFiltered: Ref<any> = ref({});
    const attOk = ref(false);
    const targetClickCarRefresh: Ref<any> = ref({
      car: {},
      products: [],
    });
    const produtosCar: Ref<any> = ref([]);
    const modelsListFiltered: Ref<Array<any>> = ref([]);

    const lubrificante_sae_ref = ref("");
    const carter_com_filtro_ref = ref("");
    const lubrificante_base_ref = ref("");
    const lubrificante_api_ref = ref("");
    const lubrificante_acea_ref = ref("");
    const lubrificante_ilsac_ref = ref("");
    const lubrificante_oem_ref = ref("");

    //produtos do carro divididos em array
    const filtroAr: Ref<Array<any>> = ref([]);
    const filtroOleo: Ref<Array<any>> = ref([]);
    const filtroCombust: Ref<Array<any>> = ref([]);
    const filtroArCond: Ref<Array<any>> = ref([]);

    //FUNCAO QUE PREENCHE OS CAMPOS DA ATUALIZACAO DE LUBRIFICANTES
    function preenche() {
      loading.value = true;
      try {
        (lubrificante_sae_ref.value = props.targetClickCar.car.lubrificante_sae),
          (carter_com_filtro_ref.value = props.targetClickCar.car.carter_com_filtro),
          (lubrificante_base_ref.value = props.targetClickCar.car.lubrificante_base),
          (lubrificante_api_ref.value = props.targetClickCar.car.lubrificante_api),
          (lubrificante_acea_ref.value = props.targetClickCar.car.lubrificante_acea),
          (lubrificante_ilsac_ref.value = props.targetClickCar.car.lubrificante_ilsac),
          (lubrificante_oem_ref.value = props.targetClickCar.car.lubrificante_oem);
        refreshCarItems(props.targetClickCar.car);
      } catch (err) {
        console.log(err);
      } finally {
        loading.value = false;
      }
    }

    //ABRIR MODAL
    // const openModal = (id) => {
    //   const auxElement = document.querySelector(`#${id}`);
    //   auxModal = new Modal(auxElement);
    //   auxModal.show();
    // };

    //PEGAR O TOKEN PARA VALIDAR AS ATUALIZACOES
    const getToken = async () => {
      return localStorage.getItem("token_usuario");
    };
    getToken();

    watch(
      () => props.targetClickCar,
      () => {
        preenche();
        refreshCarItems(props.targetClickCar.car);
      }
    );

    watch(
      () => targetClickCarRefresh.value,
      () => {
        productsFiltered.value = {};
        attOk.value = true;
      }
    );

    //PREENCHE A LISTA COM TODOS OS PRODUTOS
    function allProducts() {
      productsFiltered.value = {};
      productsFiltered.value =
        props.targetClickCar?.products && targetClickCarRefresh.value?.products
          ? targetClickCarRefresh?.value?.products
          : props.targetClickCar?.products;
      if (productsFiltered.value.length) {
        filtroAr.value = productsFiltered.value.filter(prod => prod.tipo === "filtro_ar");
        filtroOleo.value = productsFiltered.value.filter(prod => prod.tipo === "filtro_oleo");
        filtroCombust.value = productsFiltered.value.filter(prod => prod.tipo === "filtro_combustivel");
        filtroArCond.value = productsFiltered.value.filter(prod => prod.tipo === "filtro_ac");
      }
    }

    //

    //FILTRO DE ABAS POR TIPO DE PRODUTO
    const filter = (tipo: string) => {
      productsFiltered.value = {};
      if (targetClickCarRefresh.value.products.length > 0) {
        productsFiltered.value = targetClickCarRefresh.value.products.filter((product: any) => product.tipo === tipo);
      } else {
        productsFiltered.value = props.targetClickCar.products.filter((product: any) => product.tipo === tipo);
      }
    };

    //ADICIONAR PRODUTO NO CARRO
    async function addProduct(e) {
      loading.value = true;
      targetClickCarRefresh.value = props.targetClickCar.products;
      let myProd = {
        fipe: props.targetClickCar.car.fipe,
        ano: props.targetClickCar.car.ano,
        id_tipo: tipo.value,
        id_marca: marca.value,
        referencia: referencia.value,
      };
      try {
        const res = await axios.post("filtroVeiculo", myProd, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        });
        refreshCarItems(props.targetClickCar.car);
        tipo.value = "";
        marca.value = "";
        referencia.value = "";
        // allProducts();
        openLub.value = false;
        allList.value = true;
        showTimeAlert("Produto adicionado com sucesso!");
      } catch (err) {
        showTimeAlert("Não foi possivel adicionar o produto.", "error");
      } finally {
        loading.value = false;
      }
    }

    //ATUALIZA OS LUBRIFICANTES DO VEICULO
    async function attLub(e) {
      loading.value = true;
      e.preventDefault();
      let newLubConfig = {
        fipe: props.targetClickCar.car.fipe,
        ano: props.targetClickCar.car.ano,
        lubrificante_sae: lubrificante_sae_ref.value,
        carter_com_filtro: carter_com_filtro_ref.value,
        lubrificante_base: lubrificante_base_ref.value,
        lubrificante_api: lubrificante_api_ref.value,
        lubrificante_acea: lubrificante_acea_ref.value,
        lubrificante_ilsac: lubrificante_ilsac_ref.value,
        lubrificante_oem: lubrificante_oem_ref.value,
      };
      try {
        const res = await axios.post("lubrificanteVeiculo", newLubConfig, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        });
        const { lubrificante_sae, carter_com_filtro, lubrificante_base, lubrificante_api, lubrificante_acea, lubrificante_ilsac, lubrificante_oem } =
          res.data.data;

        lubrificante_sae_ref.value = lubrificante_sae;
        carter_com_filtro_ref.value = carter_com_filtro;
        lubrificante_base_ref.value = lubrificante_base;
        lubrificante_api_ref.value = lubrificante_api;
        lubrificante_acea_ref.value = lubrificante_acea;
        lubrificante_ilsac_ref.value = lubrificante_ilsac;
        lubrificante_oem_ref.value = lubrificante_oem;

        props.targetClickCar.car.lubrificante_sae = lubrificante_sae;
        props.targetClickCar.car.carter_com_filtro = carter_com_filtro;
        props.targetClickCar.car.lubrificante_base = lubrificante_base;
        props.targetClickCar.car.lubrificante_api = lubrificante_api;
        props.targetClickCar.car.lubrificante_acea = lubrificante_acea;
        props.targetClickCar.car.lubrificante_ilsac = lubrificante_ilsac;
        props.targetClickCar.car.lubrificante_oem = lubrificante_oem;

        openLub.value = false;
        allList.value = true;
        showTimeAlert("Dados do veiculo atualizado com sucesso!");
      } catch (err) {
        showTimeAlert("Não foi possivel atualizar o veiculo.", "error");
        console.log(err);
      } finally {
        loading.value = false;
      }
    }

    //ATUALIZA OS DADOS DO VEICULO A CADA ATUALIZACAO
    async function refreshCarItems(car: any) {
      const { id } = car;
      try {
        const res = await axios.post("buscaFiltro", car, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        });
        produtosCar.value = res.data;
        const productsList = produtosCar.value.data.filtros.map((item: any) => {
          return item;
        });
        let carTarget = props.targetClickCar.car;
        targetClickCarRefresh.value = {
          car: carTarget,
          products: productsList,
        };

        allProducts();
      } catch (err) {
        console.log(err);
      }
    }

    //REMOVE O PRODUTO DO VEICULO CLICADO
    async function removeProd(produto: any) {
      loading.value = true;
      let deleteProd = {
        fipe: produto.fipe,
        ano: props.targetClickCar.car.ano,
        id_tipo: produto.id_tipo_produto,
        id_marca: produto.id_marca_produto,
        referencia: produto.referencia,
      };
      try {
        const res = await axios.delete("filtroVeiculo", {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
          data: deleteProd,
        });
        refreshCarItems(props.targetClickCar.car);

        allProducts();
        allList.value = true;
        showTimeAlert("Produto removido com sucesso!");
      } catch (err) {
        showTimeAlert("Não foi possivel remover o produto.", "error");
        console.log(err);
      } finally {
        loading.value = false;
      }
    }

    function verifySelectValues(e, param) {
      tipo.value = "";
      if (param === "1") tipo.value = param;
      if (param === "2") tipo.value = param;
      if (param === "3") tipo.value = param;
      if (param === "4") tipo.value = param;

      if (!tipo.value || !marca.value || !referencia.value) {
        spanWarning.value = true;
        return true;
      }
      spanWarning.value = false;
      addProduct(e);
    }

    const openModal = (id: string) => {
      const auxElement = document.querySelector(`#${id}`);
      auxModal = new Modal(auxElement);
      auxModal.show();
    };

    let modal;
    onMounted(() => {
      modal = document.getElementById("editarUnicoSelecionado");
      modal.addEventListener("click", event => {
        if (event.target === modal) {
          // props.cleanModal = false;
          emit("close");
        }
      });
    });

    return {
      tipoSelected,
      openLub,
      addProduct,
      marca,
      tipo,
      referencia,
      productsFiltered,
      filter,
      attLub,
      attOk,
      targetClickCarRefresh,
      allProducts,
      removeProd,
      allList,
      preenche,
      lubrificante_sae_ref,
      lubrificante_base_ref,
      lubrificante_acea_ref,
      lubrificante_api_ref,
      lubrificante_ilsac_ref,
      lubrificante_oem_ref,
      carter_com_filtro_ref,
      verifySelectValues,
      spanWarning,
      filtroAr,
      filtroOleo,
      filtroCombust,
      filtroArCond,
      tipo1,
      openModal,
      refreshCarItems,
    };
  }
});
