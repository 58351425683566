
import { defineComponent, PropType } from "vue";
import { Modal } from "bootstrap";

interface ITargetClickCar {
  car: ICarInfo;
}

interface ICarInfo {
  marca: string;
  modelo: string;
}

export default defineComponent({
  emits: ["excluirSelecionadoUnico"],
  props: {
    targetClickCar: {
      type: Object as PropType<ITargetClickCar>,
      required: true,
    },
    productsFiltered: Array,
  },
  setup() {
    let auxModal;

    const openModal = id => {
      const auxElement = document.querySelector(`#${id}`);
      auxModal = new Modal(auxElement);
      auxModal.show();
    };
    return { openModal };
  },
});
