
import { defineComponent, ref, Ref, watch } from "vue";
import useAlert from "@/composables/Alert";
import axios from "axios";
import { h } from "vue";
import s3 from "@/services/S3Service";

export default defineComponent({
  name: "ModalEnviarPlanilha",
  emits: [""],
  props: {
    productsMarcas: Array,
  },
  setup(props) {
    const { showTimeAlert } = useAlert();
    const fullscreenLoading = ref(false);
    const marca: Ref<String> = ref("");
    const fileInput: Ref<any> = ref(null);
    const centerDialogVisible: Ref<boolean> = ref(false);
    const name: Ref<string> = ref("");
    const sendPlanilhaFiltros: Ref<boolean> = ref(false);
    const formdataref: Ref<any> = ref(FormData);

    // let formData;

    //FUNCAO PARA PEGAR O TOKEN
    const getToken = async () => {
      return localStorage.getItem("token_usuario");
    };

    //_____ENVIO DA PLANILHA VIA PAINEL DE GESTÃO_____

    const openInput = () => {
      fileInput.value.click();
    };

    const uploadFile = async (event: any) => {
      const file = event.target.files[0];
      name.value = file.name;

      const formData = new FormData();
      formData.append("planilha", file);
      formData.append("marca", marca.value as any);
      formdataref.value = formData;
    };

    function s3upload() {
      if (!sendPlanilhaFiltros.value) return null;
      var files = fileInput.value.files;
      if (files) {
        var file = files[0];
        var nomeArquivo = "planilha_atualizacao_filtros.xlsx";
        if (file && file.name) {
          nomeArquivo = file.name;
        }
        var pathArquivo = "AtriaLub/planilhas/atualizacao_filtros/" + nomeArquivo;
        s3.upload({ Key: pathArquivo, Body: file, ACL: "public-read" }, function (err, data) {
          if (err) {
            console.log("Erro", err);
            return;
          }

          if (data.key) {
            enviaDadosPlanilhaAtualizacao(data.key, marca.value);
          }
        });
      }
    }

    async function enviaDadosPlanilhaAtualizacao(pathArquivo, idMArca) {
      try {
        const res = await axios.post(
          "planilhaVeiculos",
          { pathArquivo: pathArquivo, idMarca: idMArca },
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        );

        if (res.data.error) {
          showTimeAlert(res.data.message, "error");
        } else {
          showTimeAlert(res.data.message);
        }
      } catch (err) {
        showTimeAlert(err, "error");
      } finally {
        fullscreenLoading.value = false;
        sendPlanilhaFiltros.value = false;
        // marca.value = "";
        name.value = "";
        (fileInput.value as HTMLInputElement).value = "";
      }
    }

    function cleanName() {
      name.value = "";
      (fileInput.value as HTMLInputElement).value = "";
    }

    const customSpinner = "Por favor aguarde a atualização e nao feche essa tela ate o processo terminar...";

    watch(
      () => sendPlanilhaFiltros.value,
      () => s3upload()
    );

    return {
      uploadFile,
      marca,
      openInput,
      centerDialogVisible,
      fileInput,
      name,
      sendPlanilhaFiltros,
      cleanName,
      fullscreenLoading,
      customSpinner,
    };
  },
});
