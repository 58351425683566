
import { defineComponent } from "vue";
import { Modal } from "bootstrap";

export default defineComponent({
  emits: ["excluirSelecionados"],
  props: {
    clickedAllVeiculos: Array,
  },
  setup() {
    let auxModal;

    const openModal = id => {
      const auxElement = document.querySelector(`#${id}`);
      auxModal = new Modal(auxElement);
      auxModal.show();
    };
    return { openModal };
  },
});
